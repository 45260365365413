// 退出登录
import request from '@/utils/request'
import { URLS } from '@/config/api'
import { SETTING } from '@/config/setting'

const StringFormat = require('format')

/**
 * 获取教辅列表
 * @param grade
 * @param subject
 * @returns {AxiosPromise}
 */
export function getMaterialList (grade, subject) {
  return request({
    url: StringFormat(URLS.MATERIAL_LIST, grade, subject),
    method: SETTING.HTTP.METHOD.GET,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
* 根据教辅ID和页码获取图片url
* @param materialId
* @param pageNumber
* @returns {AxiosPromise}
*/
export function getMaterialImage (materialId, pageNumber) {
  return request({
    url: StringFormat(URLS.MATERIAL_IMAGE, materialId, pageNumber),
    method: SETTING.HTTP.METHOD.GET,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
