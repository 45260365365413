/* eslint-disable */

const KNOB_TOL2 = 5 * 5
const KNOB_SIZE = 5

class Shape {
    constructor(props) {
        if (!props.ctx) {
            throw 'no context'
        }
        if (props.tipText === undefined) {
            throw 'no tipText'
        }
        this.ctx = props.ctx
        this.tipText = props.tipText || '' // 标注提示文字
        this.assignType = props.assignType // 指定输出的type
        this.scaleSwitch = Boolean(props.scaleSwitch) // 扩大缩小开关
        this.isOrderly = props.isOrderly || false // 有序/无序
        this.knobSize = props.knobSize || KNOB_SIZE // 节点查询大小
        this.knobTol2 = props.knobSize ? Math.pow(props.knobSize, 2) : KNOB_SIZE // 节点查询大小
        this.knobShowAlways = false // 是否一直显示节点
        this.isActive = true // 是否在激活状态
        this.autoComplete = true // 自动完成(即鼠标抬起后当前shape绘画完成，false则代表需手动触发或达到一定条件后当前shape才绘画结束)
        this.moveAble = true // shape是否可移动
        this.pathStroke = new Path2D()
        this.strokeStyle = '#ff0000'
        this.pathFill = new Path2D()
        this.fillStyle = 'rgba(128,255,0,0.3)'
        this.pathAnnotation = new Path2D()
        this.tipTextStyle = '#18C2C2'
        this.pathKnobs = []
        this.knobsFillStyle = 'red'
        this.font = '600 14px serif'
        this.textDy = 12
        this.lineWidth = 2
        this.lineJoin = 'miter'
        this.isHighlighted = false
    }

    setHighlight(on) {
        this.isHighlighted = Boolean(on)
        this.buildPath()
    }

    setActive(on) {
        this.isActive = Boolean(on)
        if (this.isActive) {
            this.tipTextStyle = 'blue'
            this.strokeStyle = '#ff0000'
        } else {
            this.tipTextStyle = '#333'
            this.strokeStyle = '#606266'
        }
        this.buildPath()
    }

    isPointOnKnob(point) {
        let points = this.getKnobs()
        let dx2, dy2
        for (let i = 0; i < points.length; i++) {
            dx2 = (points[i].x - point.x) * (points[i].x - point.x)
            dy2 = (points[i].y - point.y) * (points[i].y - point.y)
            if (dx2 + dy2 < KNOB_TOL2) {
                // console.log('highlight')
                return i
            }
        }
        return -1
    }

    isPointInPath(point) {
        return this.ctx.isPointInPath(this.pathStroke, point.x, point.y)
    }

    /**
     *
     * @param {*} ctx
     */
    draw(ctx) {
        ctx.strokeStyle = this.strokeStyle
        ctx.lineWidth = this.lineWidth
        ctx.stroke(this.pathStroke)
        this.buildKnobs()
        this.drawAnnotation(ctx)
        if (this.isHighlighted) {
            ctx.fillStyle = this.fillStyle
            ctx.fill(this.pathFill)
            ctx.fillStyle = this.knobsFillStyle
            this.pathKnobs.forEach(knob => {
                ctx.fill(knob)
            })
        }
    }

    move() {
        throw 'move() is not implemented'
    }

    scale() {
        throw 'scale() is not implemented'
    }

    buildPath() {
        throw 'buildPath() is not implemented'
    }

    isVisible() {
        return true
    }

    canCompleteDraw() {
        return true
    }

    getKnobs() {
        throw 'getKnobs() is not implemented'
    }

    buildKnobs() {
        this.pathKnobs = this.getKnobs().map(p => {
            let path = new Path2D()
            path.arc(p.x, p.y, this.knobSize, 0, Math.PI * 2)
            this.knobShowAlways && this.ctx.stroke(path)
            return path
        })
    }

    pack() {
        throw 'pack() is not implemented'
    }

    static restore() {
        throw 'restore() is not implemented'
    }

    computedTextY(centerY) {
        if (centerY < this.textDy * 2) {
            if (centerY < 0) {
                return this.textDy * 1.5
            } else {
                return centerY + this.textDy * 1.5
            }
        } else {
            return centerY - this.textDy
        }
    }
}

class Rect extends Shape {
    constructor(props) {
        super(props)
        this.startX = props.x
        this.startY = props.y
        this.x = props.x
        this.y = props.y
        this.width = props.width
        this.height = props.height
    }

    drawAnnotation(ctx) {
        let p = this.getKnobs()[0]
        let textWidth = ctx.measureText(this.tipText).width
        let dx = (this.width - textWidth) / 2
        ctx.fillStyle = this.tipTextStyle
        ctx.font = this.font
        let textX = p.x + dx
        let textY = this.computedTextY(p.y)
        ctx.fillText(this.tipText, textX, textY)
    }

    move(props) {
        this.x += props.dx
        this.y += props.dy
        this.buildPath()
    }

    scale(canvasScale) {
        this.startX *= canvasScale
        this.startY *= canvasScale
        this.x *= canvasScale
        this.y *= canvasScale
        this.width *= canvasScale
        this.height *= canvasScale
        this.buildPath()
    }

    updateDraw(point) {
        this.width = Math.abs(point.x - this.startX)
        this.height = Math.abs(point.y - this.startY)
        this.x = Math.min(this.startX, point.x)
        this.y = Math.min(this.startY, point.y)
    }

    getKnobs() {
        return [
            {x: this.x, y: this.y},
            {x: this.x, y: this.y + this.height},
            {x: this.x + this.width, y: this.y + this.height},
            {x: this.x + this.width, y: this.y}
        ]
    }

    buildPath() {
        this.pathStroke = new Path2D()
        this.pathFill = new Path2D()
        this.pathStroke.rect(this.x, this.y, this.width, this.height)
        this.pathFill.rect(this.x, this.y, this.width, this.height)
        this.buildKnobs()
    }

    isVisible() {
        // console.log('rect isVisible: ',  this.width > 3 && this.height > 3)
        return this.width > 3 && this.height > 3
    }

    toPolygon() {
        let points = this.getKnobs()
        let {tipText, assignType, scaleSwitch, isOrderly, knobSize, knobTol2} = this
        return new Polygon({
            tipText,
            assignType,
            scaleSwitch,
            isOrderly,
            points,
            knobSize,
            knobTol2,
            ctx: this.ctx
        })
    }

    pack(scale) {
        scale = scale || 1
        return [
            {
                x: this.x / scale,
                y: this.y / scale,
                width: this.width / scale,
                height: this.height / scale
            }
        ]
    }
}

class Polygon extends Shape {
    constructor(props) {
        super(props)
        this.points = props.points || [] // points are stored in anticlockwise order
        this.draggingKnobIndex = -1
        this.knobMap = { // 对角线点对应索引
            '0': 2,
            '2': 0,
            '1': 3,
            '3': 1
        }
    }

    static restore(props) {
        let points = props.data.map(ele => ({
            x: ele.x,
            y: ele.y
        }))

        let tmp = {
            ...props,
            points: points
        }
        return new Polygon(tmp)
    }

    drawAnnotation(ctx) {
        let p1 = this.getKnobs()[0],
            p4 = this.getKnobs()[3];
        let textWidth = ctx.measureText(this.tipText).width
        let dx = (Math.abs(p1.x - p4.x) - textWidth) / 2
        ctx.fillStyle = this.tipTextStyle
        ctx.font = this.font
        let textX = Math.min(p1.x, p4.x) + dx
        let centerY = Math.min(p1.y, p4.y) + Math.abs((p1.y - p4.y) / 2)
        let textY = this.computedTextY(centerY)

        // TODO: correct tipText label position
        ctx.fillText(this.tipText, textX, textY)
    }

    move(props) {
        this.points.forEach(p => {
            p.x += props.dx
            p.y += props.dy
        })
        this.buildPath()
    }

    scale(canvasScale) {
        this.points.forEach(point => {
            point.x *= canvasScale
            point.y *= canvasScale
        })
        this.buildPath()
    }

    startDrag(knob) {
        this.draggingKnobIndex = knob
    }

    drag(point) {
        // console.log(this.draggingKnobIndex)
        // let list = [1, 0, 3, 2]
        // let lenX = point.x - this.points[this.draggingKnobIndex].x;
        // let lenY = point.y - this.points[this.draggingKnobIndex].y;
        this.points[this.draggingKnobIndex].x = point.x
        this.points[this.draggingKnobIndex].y = point.y

        // this.points[list[this.draggingKnobIndex]].x += lenX
        // this.points[list[this.draggingKnobIndex]].y += lenY
        this.buildPath()
    }

    dragScale(point) {
        let centerPoint = this.points[this.knobMap[this.draggingKnobIndex]] // 取对角线的点
        let centerX = centerPoint.x
        let centerY = centerPoint.y
        let width = Math.abs(point.x - centerX)
        let height = Math.abs(point.y - centerY)
        let point0_X = Math.min(centerX, point.x)
        let point0_Y = Math.min(centerY, point.y)
        let minWidth = 1
        if (this.draggingKnobIndex === 0) {
            // if(point.x+10>=centerX || point.y+10>=centerY) return
            if (point.x + minWidth >= centerX) {
                width = minWidth;
                point0_X = this.points[this.knobMap[0]].x - minWidth
            }
            if (point.y + minWidth >= centerY) {
                height = minWidth;
                point0_Y = this.points[this.knobMap[0]].y - minWidth
            }
        } else if (this.draggingKnobIndex === 1) {
            // if(point.x+minWidth>=centerX || point.y-minWidth<=centerY) return
            if (point.x + minWidth >= centerX) {
                width = minWidth;
                point0_X = this.points[this.knobMap[1]].x - minWidth
            }
            if (point.y - minWidth <= centerY) {
                height = minWidth;
                point0_Y = this.points[this.knobMap[1]].y
            }
        } else if (this.draggingKnobIndex === 2) {
            // if(point.x-minWidth<=centerX || point.y-minWidth<=centerY) return
            if (point.x - minWidth <= centerX) {
                width = minWidth;
                point0_X = this.points[this.knobMap[2]].x
            }
            if (point.y - minWidth <= centerY) {
                height = minWidth;
                point0_Y = this.points[this.knobMap[2]].y
            }
        } else if (this.draggingKnobIndex === 3) {
            // if(point.x-minWidth<=centerX || point.y+minWidth>=centerY) return
            if (point.x - minWidth <= centerX) {
                width = minWidth;
                point0_X = this.points[this.knobMap[3]].x
            }
            if (point.y + minWidth >= centerY) {
                height = minWidth;
                point0_Y = this.points[this.knobMap[3]].y - minWidth
            }
        } else {
        }
        this.points = [
            {x: point0_X, y: point0_Y},
            {x: point0_X, y: point0_Y + height},
            {x: point0_X + width, y: point0_Y + height},
            {x: point0_X + width, y: point0_Y}
        ]
        this.buildPath()
    }

    getKnobs() {
        return this.points
    }

    buildPath() {
        this.pathStroke = new Path2D()
        this.pathFill = new Path2D()
        this.pathKnobs = []
        this.pathStroke.moveTo(this.points[0].x, this.points[0].y)
        this.pathFill.moveTo(this.points[0].x, this.points[0].y)
        for (let i = 1; i < this.points.length; i++) {
            this.pathStroke.lineTo(this.points[i].x, this.points[i].y)
            this.pathFill.lineTo(this.points[i].x, this.points[i].y)
        }
        this.pathStroke.closePath()
        this.pathFill.closePath()
        this.buildKnobs()
    }

    pack(scale) {
        scale = scale || 1
        return this.points.map(point => ({
            x: point.x / scale,
            y: point.y / scale
        }))
    }
}

const shapeClasses = {
    rect: Rect,
    polygon: Polygon
}
const canvasModes = {
    DRAW: 'draw', // 画
    DRAG: 'drag', // 拖拽点
    DRAG_SCALE: 'dragScale',  // 拖拽点缩放
    MOVE: 'move', // 移动shape
    DEFAULT: 'default'  // 默认
}

// eslint-disable-next-line
class Canvas {
    constructor(ctx) {
        this.ctx = ctx
        this.shapeList = []
        this.typeList = []
        this.shapeListName = []
        this.startX = 0
        this.startY = 0
        this.drawingShape = 'point'
        this.drawingShapeObject = null
        this.movingShapeObject = null
        this.draggingShapeObject = null
        this.scalingShapeObject = null
        this.mode = canvasModes.DEFAULT
    }

    deleteAll() {
        this.shapeList = []
        this.typeList = []
        this.shapeListName = []
    }

    setDrawingShape(props) {
        this.drawingShape = props.shape
    }

    pack(scale) {
        return this.shapeList.map((shape, index) => ({
            data: shape.pack(scale),
            shape: this.shapeListName[index]
        }))
    }

    /**
     * 删除选中的HighLight的shape
     * @param {points} props 鼠标位置
     * drawingShapeObject movingShapeObject draggingShapeObject 是否需要修改?
     */
    removeShape(props) {
        if (this.shapeList.length === 0 && !this.drawingShapeObject) return

        // 当有正在绘画的图象时，不可操作其他图像
        if (this.drawingShapeObject) {
            let knob = this.drawingShapeObject.isPointOnKnob(props)
            if (knob !== -1) {
                this.drawingShapeObject.points.splice(knob, 1)
                if (this.drawingShapeObject.points.length === 0) this.drawingShapeObject = null
            }
            return
        }

        // 判断删除元素索引
        let removeIndex = -1
        for (let i = 0; i < this.shapeList.length; i++) {
            let shape = this.shapeList[i]
            if (!shape.isActive) continue
            if (shape.isPointInPath(props) || shape.isPointOnKnob(props) !== -1) {
                removeIndex = i
            }
        }
        if (removeIndex === -1) return


        // 提前存储下来删除前的各个shape上的文字提示 如为有序标注 则在下面用来更新
        let preAnnotations = this.shapeList.map((e, i) => {
            return e.tipText
        })

        this.shapeList.splice(removeIndex, 1)
        this.shapeListName.splice(removeIndex, 1)
        this.typeList.splice(removeIndex, 1)
        // console.log(shape, type)
        this.shapeList.forEach((shape, i) => {
            // 如为有序 更新tipText 即 文字提示
            if (shape.isOrderly) shape.tipText = preAnnotations[i]
            // 取消高亮
            if (shape.isHighlighted === true) {
                shape.setHighlight(false)
            }
        })
        for (let i = 0; i < this.shapeList.length; i++) {
            let shape = this.shapeList[i]
            if (!shape.isActive) continue
            if (shape.isPointInPath(props) || shape.isPointOnKnob(props) !== -1) {
                shape.setHighlight(true)
                break
            }
        }

        this.clear()
        this.render()
    }

    leftMouseDown(props) {
        /* 当鼠标划出canvas区域并up，此时mode仍为DRAW，再次点击时需结束,并重置mode为DEFAULT */
        // console.log('leftMouseDown:', this.mode, this.drawingShapeObject)
        if (this.mode === canvasModes.DRAW && this.drawingShapeObject) {
            this.endDraw(props)
            this.mode = canvasModes.DEFAULT
            return
        }
        // console.log(this.shapeList)
        // 因 判断在点上是设置误差值的，所以在点上时（isPointOnKnob）不一定在path（isPointInPath）上
        // let shapeListAll = this.drawingShapeObject ? this.shapeList.concat([this.drawingShapeObject]) : this.shapeList.concat([])
        for (let i = 0; i < this.shapeList.length; i++) {
            let shape = this.shapeList[i]
            if (!shape.isActive) continue
            /* DRAG */
            let knob = shape.isPointOnKnob(props)
            if (knob !== -1) {
                if (shape instanceof Rect) {
                    // Rect will be dragged into a polygon
                    shape = shape.toPolygon()
                    this.shapeListName.splice(i, 1, 'polygon')
                    this.shapeList.splice(i, 1, shape)
                }
                shape.startDrag(knob)
                this.draggingShapeObject = shape
                if (shape.scaleSwitch) {
                    this.mode = canvasModes.DRAG_SCALE
                } else {
                    this.mode = canvasModes.DRAG
                }
                return
            }
            /* MOVE */
            if (shape.isPointInPath(props) && shape.moveAble) {
                this.movingShapeObject = shape
                this.mode = canvasModes.MOVE
                return
            }
        }
        this.mode = canvasModes.DRAW
        this.startDraw(props)
    }

    leftMouseUp(props) {
        switch (this.mode) {
            case canvasModes.DRAW:
                this.endDraw(props)
                break
            case canvasModes.MOVE:
            case canvasModes.DRAG:
            case canvasModes.DRAG_SCALE:
            case canvasModes.DEFAULT:
                break
            default:
                throw `Illegal mode ${this.mode} on mouseUp`
        }
        // this.draggingShapeObject = null
        this.mode = canvasModes.DEFAULT
    }

    leftMouseMove(props) {
        // console.log(this.mode, this.drawingShapeObject)
        switch (this.mode) {
            case canvasModes.DRAW:
                this.updateDraw(props)
                break
            case canvasModes.MOVE:
                this.movingShapeObject.move(props)
                break
            case canvasModes.DRAG:
                this.draggingShapeObject.setHighlight(true)
                this.draggingShapeObject.drag(props)
                break
            case canvasModes.DRAG_SCALE:
                this.draggingShapeObject.setHighlight(true)
                this.draggingShapeObject.dragScale(props)
                break
            case canvasModes.DEFAULT:
                if (this.drawingShapeObject && this.drawingShapeObject.mouseTracking) this.drawingShapeObject.mouseTracking(props)
                this.setHighlight(props)
                break
        }
        this.clear()
        this.render()
    }

    addShape(shape, shapeName) {
        // console.log("shape是",type)
        if (!(shape instanceof Shape)) {
            throw `${shape} is not an instance of Shape`
        }
        this.shapeList.push(shape)
        this.shapeListName.push(shapeName)
        this.typeList.push(shape.assignType)
    }

    clear() {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
    }

    render() {
        // console.log('render')
        if (this.mode === canvasModes.DRAG) {
            this.draggingShapeObject.draw(this.ctx)
        }
        if (this.drawingShapeObject) {
            this.drawingShapeObject.draw(this.ctx)
        }
        this.shapeList.forEach((shape, i) => {
            shape.draw(this.ctx)
        })

        // 当前draw的并未在shapeList中，故需要单独画出
        if (this.drawingShapeObject !== null) {
            this.ctx.strokeStyle = this.drawingShapeObject.strokeStyle
            this.ctx.lineJoin = this.drawingShapeObject.lineJoin
            this.ctx.lineWidth = this.drawingShapeObject.lineWidth
            this.ctx.stroke(this.drawingShapeObject.pathStroke)
            if (this.drawingShapeObject.pathKnobs && this.drawingShapeObject.knobShowAlways) {
                this.drawingShapeObject.pathKnobs.forEach(e => {
                    this.ctx.stroke(e)
                })
            }
        }
    }

    startDraw(props) {
        this.setDrawingShape(props)
        this.startX = props.x
        this.startY = props.y
        if (!this.drawingShapeObject || this.drawingShapeObject.autoComplete) {
            this.drawingShapeObject = new shapeClasses[this.drawingShape]({
                ...props,
                ctx: this.ctx
            })
        } else {
            this.continueDraw(props)
        }
        this.mode = canvasModes.DRAW
    }

    endDraw(props) {
        if (!this.drawingShapeObject) return
        if (this.drawingShapeObject.autoComplete || this.drawingShapeObject.canCompleteDraw(props)) {
            if (this.drawingShapeObject.isVisible()) {
                this.addShape(this.drawingShapeObject, this.drawingShape)
            }
            this.drawingShapeObject = null
        } else {
            // wait continue
        }
        this.clear()
        this.render()
    }

    updateDraw(props) {
        if (this.drawingShapeObject !== null) {
            if (
                this.drawingShapeObject instanceof Rect
            ) {
                this.drawingShapeObject.updateDraw(props)
            }
            this.drawingShapeObject.buildPath()
        }
    }

    continueDraw(props) {
        if (this.drawingShapeObject.continueDraw) {
            this.drawingShapeObject.continueDraw(props)
        } else {
            this.updateDraw(props)
        }
    }

    cancelDraw() {
        this.drawingShapeObject = null
        this.clear()
        this.render()
    }

    setHighlight(props) {
        this.shapeList.forEach(shape => {
            if (shape.isHighlighted === true) {
                shape.setHighlight(false)
            }
        })
        for (let i = 0; i < this.shapeList.length; i++) {
            let shape = this.shapeList[i]
            if (!shape.isActive) continue
            if (shape.isPointInPath(props) || shape.isPointOnKnob(props) !== -1) {
                shape.setHighlight(true)
                break
            }

        }

    }

    startMove(props) {
        this.startX = props.x
        this.startY = props.y
    }

    setShapeActiveByType(type) {
        this.shapeList.forEach(shape => {
            shape.setActive(shape.assignType == type)
        })
        this.clear()
        this.render()
    }

    isDefault() {
        return this.mode === canvasModes.DEFAULT
    }
}

export {
    Shape,
    Rect,
    Polygon,
    shapeClasses,
    canvasModes,
    Canvas
}
/* eslint-disable */
