/* eslint-disable */
export default class ImageCanvas {
  constructor(ctx) {
    this.ctx = ctx
    this.currentImage = null
    this.currentImageFilename = null
  }
  init(props) {
    this.currentImage = props.image
    this.currentImageFilename = props.imageFilename
  }
  clear() {
    this.currentImage = null
    this.currentImageFilename = null
  }
  draw(oldW, oldH, rotateAngle, scale) {
    if (rotateAngle === 0 || rotateAngle === 180) {
      this.ctx.clearRect(0, 0, oldW, oldH)
    } else if (rotateAngle === 90 || rotateAngle === 270) {
      this.ctx.clearRect(0, 0, oldH, oldW)
    }

    if (this.currentImage) {
      let newW = oldW * scale
      let newH = oldH * scale

      this.ctx.rotate((Math.PI / 180) * rotateAngle)
      if (rotateAngle === 0) {
        this.ctx.drawImage(this.currentImage, 0, 0, newW, newH)
      } else if (rotateAngle === 90) {
        this.ctx.drawImage(this.currentImage, 0, -newH, newW, newH)
      } else if (rotateAngle === 180) {
        this.ctx.drawImage(this.currentImage, -newW, -newH, newW, newH)
      } else if (rotateAngle === 270) {
        this.ctx.drawImage(this.currentImage, -newW, 0, newW, newH)
      } else {
        throw `illegal angle (${rotateAngle})`
      }
      this.ctx.rotate((Math.PI / 180) * -rotateAngle)
    }
  }
}
/* eslint-disable */
